<template>
  <campaign-layout
    :showMenu="computed_debug"
    :showBack="computed_debug"
    :pageTitle="$t('pageTitle.Kpi')"
  >
    <div v-if="kpis && kpis.length">
      <transition-group
        name="block-slide-in"
        :style="{ '--total': kpis.length }"
      >
        <template v-for="(kpi, i) in kpis">
          <ion-card
            :key="i"
            :style="{ '--i': i }"
            v-if="showList1 && showList2"
            @click="
              (kpi.kpiGruppo && kpi.kpiGruppo.length) || kpi.kpiHistoryLabel
                ? (kpi.expanded = !kpi.expanded)
                : null
            "
          >
            <ion-card-header>
              <!--            <ion-card-subtitle>Card Subtitle</ion-card-subtitle>-->
              <div class="percentage">
                <div class="flex justify-content-end align-items-center">
                  <span class="text-10"
                    >{{ kpi.valore.toFixed(0) }} /
                    {{ kpi.goal.toFixed(0) }}</span
                  >
                  <span class="ml-4 font-bold"
                    >{{ kpi.perc_completamento.toFixed(0) }}%</span
                  >
                </div>
              </div>
              <div class="title">
                <ion-card-title class="text-20">
                  <ion-row class="ion-justify-content-around">
                    <ion-col size="1" class="ion-no-padding">
                      <div style="opacity: 0.6">
                        <ion-icon
                          v-if="kpi.kpiGruppo"
                          :icon="peopleCircle"
                        ></ion-icon>
                        <ion-icon v-else :icon="personCircle"></ion-icon>
                      </div>
                    </ion-col>
                    <ion-col size="10" class="ion-no-padding">
                      {{ $root.translate(kpi.friendlyName) }}
                    </ion-col>
                  </ion-row>
                </ion-card-title>
              </div>
              <div class="common-bar-container">
                <div class="light-title" v-if="kpi.Name">
                  {{ kpi.Name }}
                </div>
                <common-progress-bar
                  v-show="!kpi.expanded"
                  :value="kpi.perc_completamento / 100"
                ></common-progress-bar>
              </div>
              <template v-if="kpi.expanded">
                <div v-if="kpi.kpiHistoryLabel">
                  <!-- <kpi-graph
                    :prop_dataSet="[
                      {
                        data: kpi.kpiHistoryValue,
                        borderColor: '#ffffff',
                        backgroundColor: '#ffffff',
                        fill: {
                          target: 'origin',
                          above: '#ffffff' + '33',
                        },
                        tension: 0.6,
                      },
                    ]"
                    :labelsValues="kpi.kpiHistoryLabel"
                  >
                  </kpi-graph> -->
                  <div class="light-title" v-if="kpi.Name">
                    {{ kpi.Name }}
                  </div>

                  <kpi-graph
                    :prop_dataSet="[
                      { data: kpi.kpiHistoryValue, color: primaryColor },
                      { data: kpi.kpiHistoryGoal, color: secondaryColor },
                    ]"
                    :labelsValues="kpi.kpiHistoryLabel"
                    :prop_options="{ fontColor: 'white', colorXY: 'white' }"
                  ></kpi-graph>
                </div>

                <div v-else>
                  <div
                    v-for="kpiUser of kpigroup_inOrdine(kpi.kpiGruppo)"
                    :key="kpiUser.idUser"
                    class="ion-no-padding"
                  >
                    <div v-if="kpiUser.kpiHistoryLabel" class="graph-container">
                      <ion-row>
                        <ion-col size="auto">
                          <div class="avatar-container">
                            <ion-avatar v-if="!kpiUser.Name">
                              <img
                                v-if="kpiUser.UserImage"
                                :src="kpiUser.UserImage"
                                @error="handleImgError"
                              />
                              <img
                                v-else
                                src="../../../public/assets/img/avatar_placeholder.png"
                                @error="handleImgError"
                              />
                            </ion-avatar>
                          </div>
                        </ion-col>
                        <ion-col>
                          <div class="light-title" v-if="kpiUser.Name">
                            {{ kpiUser.Name }}
                          </div>

                          <kpi-graph
                            :prop_dataSet="[
                              {
                                data: kpiUser.kpiHistoryValue,
                                color: primaryColor,
                              },
                              {
                                data: kpiUser.kpiHistoryGoal,
                                color: secondaryColor,
                              },
                            ]"
                            :labelsValues="kpiUser.kpiHistoryLabel"
                            :prop_options="{
                              fontColor: 'white',
                              colorXY: 'white',
                            }"
                          ></kpi-graph>
                        </ion-col>
                      </ion-row>
                    </div>

                    <div v-else class="progress-container">
                      <div class="light-title" v-if="kpiUser.Name">
                        {{ kpiUser.Name }}
                      </div>
                      <!-- <div style="margin-bottom: 1rem">
                        {{ kpiUser.valore }} /
                        {{ dynamicGoal(kpi, kpiUser.valore) }} ({{
                          kpiUser.valore
                        }}
                        +
                        {{ dynamicGoal(kpi, kpiUser.valore) - kpiUser.valore }})
                      </div> -->
                      <div
                        v-if="percIsLegit(kpi.valore, kpi.goal)"
                        style="margin-bottom: 0.5rem"
                      >
                        <!-- {{
                          (
                            (kpiUser.valore /
                              dynamicGoal(kpi, kpiUser.valore)) *
                            100
                          ).toFixed(2)
                        }}% -->
                        {{ percIsLegit(kpi.valore, kpi.goal) }}%
                      </div>
                      <!-- <common-progress-bar
                        :value="
                          kpiUser.valore / dynamicGoal(kpi, kpiUser.valore)
                        "
                        :avatar="kpiUser.UserImage"
                      ></common-progress-bar> -->
                      <common-progress-bar
                        :value="
                          kpiUser.valore /
                          Math.max(
                            kpi.valore / kpi.kpiGruppo.length,
                            kpi.topValue
                          )
                        "
                        :avatar="kpiUser.UserImage"
                      ></common-progress-bar>
                    </div>
                  </div>
                </div>
              </template>
            </ion-card-header>
          </ion-card>
        </template>
      </transition-group>
    </div>
  </campaign-layout>
</template>

<script>
import { arrowForward, peopleCircle, personCircle } from "ionicons/icons";
import ApiService from "../../common/service.api";
import { translate } from "../../common/helper.auth";
import CampaignLayout from "../../components/CampaignLayout";
import { useRoute } from "vue-router";
import ItemRanking from "../../components/ItemRanking";
import {
  IonIcon,
  IonRow,
  IonCol,
  IonAvatar,
  IonCardHeader,
  IonCardTitle,
  IonProgressBar,
  IonCard,
} from "@ionic/vue";
import CommonProgressBar from "../../components/CommonProgressBar";
import KpiGraph from "../../components/KpiGraph.vue";
export default {
  name: "Kpi",
  data() {
    return {
      peopleCircle,
      personCircle,
      kpis: null,
      showList1: false,
      showList2: false,
      primaryColor: "",
      secondaryColor: "",
    };
  },
  components: {
    IonIcon,
    IonRow,
    IonCol,
    IonAvatar,
    IonCardHeader,
    IonCardTitle,
    IonCard,
    CommonProgressBar,
    CampaignLayout,
    ItemRanking,
    IonProgressBar,
    KpiGraph,
  },
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    percIsLegit(valore, goal) {
      if (valore && goal) {
        return ((valore / goal) * 100).toFixed(2);
      } else {
        return false;
      }
    },
    //     dynamicGoal(kpi, userValore) {
    //       return userValore + (kpi.goal - kpi.valore) / kpi.kpiGruppo.length;
    //     },
    kpigroup_inOrdine(kpiGruppo) {
      let kpiGruppoOrdinato = kpiGruppo.sort((a, b) => b.valore - a.valore);
      return kpiGruppoOrdinato;
    },
  },
  setup() {
    const route = useRoute();
    const { id } = route.params;
    return { id };
  },
  created() {
    this.primaryColor = this.whappyClientConfig.graphDarkColor_1;
    this.secondaryColor = this.whappyClientConfig.graphDarkColor_2;
  },
  ionViewWillEnter() {
    const route = this.$route;
    const { id } = route.params;
    this.showList1 = false;
    this.showList2 = false;
    ApiService.get("CampaignManager/GetUserKpi/" + id, {})
      .then((res) => {
        console.log("GET USER KPI    -- ", res);
        this.kpis = res.data;
        console.log(res.data);
        setTimeout(() => {
          this.$nextTick(() => {
            this.showList1 = true;
          });
        }, 1);
      })
      .finally(() => {
        this.kpis.forEach((kpi) => {
          if (kpi.kpiGruppo) {
            kpi.kpiGruppo.forEach((kpiGruppo) => {
              kpiGruppo.kpiHistoryGoal = kpiGruppo.kpiHistoryGoal.map(
                (item) => {
                  return item / kpiGruppo.kpiHistoryGoal.length;
                }
              );
            });
          }
        });
      });
  },
  ionViewDidEnter() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.showList2 = true;
      });
    }, 1);
  },

  computed: {
    computed_debug() {
      if (this.$cookies.get("debug") == 1) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
ion-card {
  //--background: var(--ion-color-primary-h);
  --background: var(--ion-color-primary-shade);
  color: var(--text-color-light);
  ion-card-title {
    color: var(--text-color-light);
  }

  ion-icon {
    font-size: 150%;
    margin: 0 5px -3px 0;
  }
}
.percentage {
  width: 100%;
  float: left;
  text-align: right;

  color: var(--text-color-light);
}
.title {
  display: block;
  clear: both;
}
ion-card {
  ion-button {
    margin-top: 15px;
  }
  ion-button::part(native) {
    background: var(--text-color-light);
    color: var(--ion-color-primary);
  }
}

.graph-container {
  margin: 20px 0;
}

.progress-container {
  position: relative;
  margin: 70px 0 70px;
  padding-right: 1rem;
  padding-left: 1rem;
  &:last-child {
    margin-bottom: 30px;
  }
}
.common-bar-container {
  margin-top: 10px;
  //   padding-left: 3rem;
}
</style>
